





































import {Component, Vue} from 'vue-property-decorator';
import {generateSlug} from '@/utils';
import PageTitle from '@/components/page-title.vue';
import {blogs} from '@/content';

@Component({
  components: {PageTitle},
  metaInfo: {
    title: 'Blogs List - Ocere',
    meta: [
      {
        content:
          'Browse our blog for insights into digital marketing trends as well as expert advice to help improve your organic presence and create better strategies. Click here to read more.',
        hid: 'description',
        name: 'description',
      },
    ],
  },
})
export default class extends Vue {
  blogs = [];
  async mounted() {
    this.blogs = blogs;
  }

  doRedirect(url) {
    this.$router.push({
      path: '/blog/' + url,
    });
  }
}
